//ユーザーエージェントを使用するJSはこちらに記述する（全体共通）。

$(function(){
  var isMobile = false;
  if ('userAgentData' in window.navigator) {
    // userAgentData が有効なので、userAgentDataで判定をする
    const useAgentDataMobile = navigator.userAgentData.mobile;
    if( useAgentDataMobile ){
      isMobile = true;
    }
  }
  else {
    // userAgentData が無効なので、既存のユーザーエージェント判定をする
    if ( (navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i)) || (navigator.userAgent.match(/Android/i)) || (navigator.userAgent.match(/Blackberry/i)) || (navigator.userAgent.match(/Windows Phone/i)) ) {
      isMobile = true;
    }
  }

  if ( isMobile ) {
    activeTelEvent();
  }
});


//電話番号を押せるように
function activeTelEvent() {
  $('.c-tel').addClass('is-telActive');
}